import { useRef, useLayoutEffect } from 'react';

/**
 * Wrap a callback into a `ref`. Good use case for an "effect" where you don't
 * want to execute the effect if the `callback` reference changes.
 * E.g if the Component accepts an event handler and use this event handler
 * inside an effect callback that runs a "fetch". Most likely you don't want
 * the "fetch" to trigger if the event handler reference changes.
 * */
export function useCallbackRef(callback) {
  const callbackRef = useRef(callback);

  /**
   * Here we use "layout effect" to reinitialize the `ref` instead pass "passive" effect
   * to make sure the "reinitialization" happens first before the "effect" calllback
   * run.
   * */
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return callbackRef;
}
