import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { RadioGroupProps } from './interface';
import { CustomizedRadios } from './CustomizeRadios';

const BasicRadios = (props: RadioGroupProps) => {
  const { options, RadioProps = {} } = props;
  return (
    <RadioGroup row {...props}>
      {options.map((option) => (
        <FormControlLabel
          key={`label-${option.label}`}
          control={<Radio color="primary" {...RadioProps} />}
          value={option.value}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

const SmarterRadios = (props: RadioGroupProps) => {
  const { isShowCustomizeRadio } = props;

  return isShowCustomizeRadio ? <CustomizedRadios {...props} /> : <BasicRadios {...props} />;
};

export default SmarterRadios;
